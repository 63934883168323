html {
  background: url(/gorilla.jpg) no-repeat center center fixed;
  background-size: cover;
}
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
}
#root {
    width: 100%;
    height: 100%;
    display: flex;
}
.App {
    padding: 8px;
    background-color: rgba(255,255,255,0.5);
    flex: 1 1;
    overflow-y: auto;
}

.App > .Row {
    overflow-x: auto;
    display: flex;
    margin-left: 32px;
}

.App > .Row > .Col {
    width: 320px;
    margin: 8px;
    margin-left: 0;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

