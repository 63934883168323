.App {
    padding: 8px;
    background-color: rgba(255,255,255,0.5);
    flex: 1;
    overflow-y: auto;
}

.App > .Row {
    overflow-x: auto;
    display: flex;
    margin-left: 32px;
}

.App > .Row > .Col {
    width: 320px;
    margin: 8px;
    margin-left: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
