html {
  background: url(/gorilla.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
}
#root {
    width: 100%;
    height: 100%;
    display: flex;
}